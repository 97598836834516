<template>
  <v-data-table
    v-if="tickets.length > 0"
    class="elevation-0 table-transparent"
    :headers="headers"
    :items="tickets"
    hide-default-footer>
    <template v-slot:item="{ item }">
      <tr>
        <td>{{ $d(item.dueAt.toDate ? item.dueAt.toDate() : new Date(item.dueAt).getTime(), 'short') }}</td>
        <td class="text-center">
          {{ $n(item.originalValue, 'currency') }}
        </td>
        <td
          v-for="(detail, index) in item.details"
          :key="index"
          class="text-center">
          {{ $n(detail.value, 'currency') }}
        </td>
        <td class="text-center">
          {{ $n(item.currentValue, 'currency') }}
        </td>
      </tr>
      <tr v-if="tickets.indexOf(item) === tickets.length - 1">
        <td><strong>Total Geral</strong></td>
        <td class="text-center">
          <strong>{{ $n(totalOriginal, 'currency') }}</strong>
        </td>
        <td
          v-for="(detail, index) in details"
          :key="index"
          class="text-center">
          <strong>{{ $n(detail.value, 'currency') }}</strong>
        </td>
        <td class="text-center">
          <strong>{{ $n(totalCurrent, 'currency') }}</strong>
        </td>
      </tr>
    </template>
  </v-data-table>
  <loading
    v-else
    class="absolute-center" />
</template>

<script>
  import Loading from '@/components/ui/Loading'

  export default {
    components: { Loading },
    props: {
      tickets: {
        type: Array,
        default: () => [],
        required: true
      },
      details: {
        type: Array,
        default: () => [],
        required: true
      }
    },
    data () {
      return {
        headers: [
          { text: 'Vencimento', value: 'date', align: 'center' },
          { text: 'Valor Original', value: 'original', align: 'center' },
          { text: 'Correção monetária', value: 'correction_index', align: 'center' },
          { text: 'Multa', value: 'mulfineta', align: 'center' },
          { text: 'Juros', value: 'interest_rate', align: 'center' },
          { text: 'Honorários', value: 'fee', align: 'center' },
          { text: 'Total', value: 'currentValue', align: 'center' }
        ]
      }
    },
    computed: {
      totalOriginal () {
        return this.tickets.reduce((acc, item) => acc + item.originalValue, 0)
      },
      totalCurrent () {
        return this.tickets.reduce((acc, item) => acc + item.currentValue, 0)
      }
    }
  }
</script>
