<template>
  <v-layout
    row
    wrap
    align-baseline>
    <v-flex
      xs4
      sm4
      class="py-0">
      <span
        v-if="param.type == 'correctionIndex'"
        class="gray--text mb-0 body-2">{{ param.name }}</span>
      <span
        v-else
        class="gray--text mb-0 body-2">{{ $t('calculateParams.' + param.type) }}</span>
    </v-flex>
    <v-flex
      xs2
      sm2
      class="py-0">
      <span class="darkGray--text mb-0 subtitle-1">{{ $n(param.value, 'currency') }}</span>
    </v-flex>
    <v-flex
      xs3
      sm3
      class="py-0">
      <v-number-field
        required
        class="py-0"
        :max="param.value"
        @change="calculate" />
    </v-flex>
    <v-flex
      xs3
      sm3
      class="py-0 text-center">
      <span class="darkGray--text mb-0 subtitle-1">{{ $n(param.value - param.discountValue, 'currency') }}</span>
    </v-flex>
  </v-layout>
</template>

<script>
  export default {
    props: {
      param: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      currentValue: ''
    }),
    methods: {
      calculate ({discount, isCurrency}) {
        let param = this.param
        let type = param.type

        discount = discount * 100
        this.$emit('change', {type, discount, isCurrency})
      }
    }
  }
</script>
