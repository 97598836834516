<template>
  <v-container class="pa-0">
    <chat-messages
      v-if="this.case && this.case.path"
      empty-class="absolute-center"
      :disabled="chatDisabled"
      :thread="this.case.path"
      @createProposal="createProposal" />

    <modal
      :show="showModalProposal"
      fullscreen
      central
      hide-actions
      @close="closeModalProposal">
      <span slot="title">Criar uma nova proposta</span>
      <proposal-create
        v-if="showModalProposal"
        slot="content"
        @cancel="closeModalProposal" />
    </modal>
  </v-container>
</template>

<script>
  import ChatMessages from '@/components/ui/ChatMessages/List'
  import Modal from '@/components/ui/Modal'
  import ProposalCreate from './ProposalCreate'
  import { mapGetters } from 'vuex'

  export default {
    components: { ChatMessages, Modal, ProposalCreate },
    data () {
      return {
        showModalProposal: false
      }
    },
    computed: {
      ...mapGetters({
        case: 'cases/record'
      }),
      chatDisabled () {
        return this.case && this.case.state !== 'negotiation' || this.getGroups.includes('superuser') || this.getGroups.includes('administrator')
      }
    },
    beforeMount () {
      this.$nextTick(() => {
        this.$store.dispatch('notifications/read', this.$route.params.caseId)
      })
      this.$store.commit('app/setLoading', false)
    },
    methods: {
      createProposal () {
        this.showModalProposal = true
      },
      closeModalProposal () {
        this.showModalProposal = false
      }
    }
  }
</script>
