<template>
  <v-container
    grid-list-lg
    class="px-0 mb-6">
    <v-expansion-panel class="elevation-0 pa-0 expand-details">
      <v-expansion-panel-content
        expand-icon="mdi-menu-down"
        class="pa-0">
        <div
          slot="header"
          class="px-0 text-right">
          <h2 class="body-2 primary--text">
            detalhes da dívida
          </h2>
        </div>
        <v-card>
          <tickets-table
            :tickets="this.case.tickets"
            :details="this.case.details" />
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-form
      ref="form"
      v-model="valid"
      class="mt-6 form-proposal"
      lazy-validation>
      <v-layout
        row
        wrap
        class="pt-2">
        <v-flex
          xs4
          sm4
          class="pb-0">
          <span class="gray--text mb-0 body-2">Quantidade de parcelas</span>
        </v-flex>
        <v-flex
          xs4
          sm4
          class="pb-0">
          <span class="gray--text mb-0 body-2">Correção Monetária</span>
        </v-flex>
        <v-flex
          xs4
          sm4
          class="pb-0">
          <span class="gray--text mb-0 body-2">Primeiro vencimento</span>
        </v-flex>
      </v-layout>
      <v-layout
        row
        wrap>
        <v-flex
          xs4
          sm4
          class="pt-0">
          <v-text-field
            ref="focusElem"
            v-model.number="proposal.installment.parcel"
            :disabled="loading != 0"
            class="py-0 validation-absolute"
            data-cy="proposalParcel"
            type="number"
            :rules="numberRules"
            required />
        </v-flex>
        <v-flex
          xs4
          sm4
          class="pt-0">
          <v-select
            v-model="correctionIndex"
            :disabled="loading != 0"
            class="pt-0"
            value="id"
            item-text="id"
            item-value="id"
            placeholder="Selecione"
            data-cy="proposalCorrectionIndex"
            :items="indexes"
            :rules="requiredRules"
            :menu-props="{contentClass:'proposalCorrectionIndex'}" />
        </v-flex>
        <v-flex
          xs4
          sm4
          class="pt-0">
          <v-select
            ref="focusElem"
            v-model.number="proposal.days"
            :items="days"
            :disabled="loading != 0"
            class="py-0 validation-absolute"
            data-cy="proposalDays"
            dense />
        </v-flex>
      </v-layout>

      <v-layout
        row
        wrap
        align-baseline
        class="mb-2">
        <v-flex
          xs4
          sm4
          class="py-0">
          <b class="gray--text mb-0 body-2">Tipo</b>
        </v-flex>
        <v-flex
          xs2
          sm2
          class="py-0 text-left">
          <b class="gray--text mb-0 body-2">Valor</b>
        </v-flex>
        <v-flex
          xs3
          sm3
          class="py-0 text-center">
          <b class="gray--text mb-0 body-2">Desconto</b>
        </v-flex>
        <v-flex
          xs3
          sm3
          class="py-0 text-center">
          <b class="gray--text mb-0 body-2">Valor a ser Pago</b>
        </v-flex>
      </v-layout>

      <v-layout
        v-if="loading === 0"
        row
        wrap
        align-baseline
        class="mb-2">
        <v-flex
          xs4
          sm4
          class="py-0">
          <b class="gray--text mb-0 body-2">Valor Original</b>
        </v-flex>
        <v-flex
          xs2
          sm2
          class="py-0">
          <b class="darkGray--text mb-0 subtitle-1">{{ $n(proposal.originalValue - proposal.allowedOriginalAmount, 'currency') }}</b>
        </v-flex>
        <v-flex
          xs3
          sm3
          class="py-0">
          <v-number-field
            :fixed-type="true"
            :is-currency="false"
            required
            class="py-0"
            :max="100"
            @change="setDiscountGeneral" />
        </v-flex>
        <v-flex
          xs3
          sm3
          class="py-0 text-center">
          <b class="darkGray--text mb-0 subtitle-1">{{ $n(originalValue, 'currency') }}</b>
        </v-flex>
      </v-layout>

      <div class="wrapper-calculate-params">
        <template v-if="loading === 0">
          <calculate-param
            v-for="(param, index) in calculateParams"
            :key="index"
            :param="param"
            @change="calculateParamChange" />
        </template>
        <loading
          v-else
          class="absolute-center" />
      </div>

      <h2 v-if="proposal.allowedTickets.length > 0" class="title">
        Dispensa de valores - Informativo
      </h2>
      <v-layout
        v-if="proposal.allowedTickets.length > 0"
        row
        wrap
        align-baseline
        class="mb-2 bgColor">
        <v-flex
          xs4
          sm4
          class="py-1">
          <b class="gray--text mb-0 body-2">Descricao</b>
        </v-flex>
        <v-flex
          xs2
          sm2
          class="py-0 text-left">
          <b class="gray--text mb-0 body-2">Vencimento</b>
        </v-flex>
        <v-flex
          xs3
          sm3
          class="py-0 text-center">
          <b class="gray--text mb-0 body-2">Valor Original</b>
        </v-flex>
        <v-flex
          xs3
          sm3
          class="py-0 text-center">
          <b class="gray--text mb-0 body-2">Valor Atualizado</b>
        </v-flex>
      </v-layout>

      <v-layout
        v-for="(item, index) in proposal.allowedTickets"
        :key="index"
        row
        wrap
        align-baseline
        class="mb-2">
        <v-flex
          xs4
          sm4
          class="py-0">
          <b class="gray--text mb-0 body-2">{{ item.description }} {{ item.id }}</b>
        </v-flex>
        <v-flex
          xs2
          sm2
          class="py-0 text-left">
          <b class="gray--text mb-0 body-2">{{ formatDate(item.dueAt) }}</b>
        </v-flex>
        <v-flex
          xs3
          sm3
          class="py-0 text-center">
          <b class="gray--text mb-0 body-2">{{ $n(item.originalValue, 'currency') }}</b>
        </v-flex>
        <v-flex
          xs3
          sm3
          class="py-0 text-center">
          <b class="gray--text mb-0 body-2">{{ $n(item.currentValue, 'currency') }}</b>
        </v-flex>
      </v-layout>

      <v-layout
        v-if="proposal.allowedTickets.length > 0"
        row
        wrap
        align-baseline
        class="mb-10">
        <v-flex
          xs4
          sm4
          class="py-0" />
        <v-flex
          xs2
          sm2
          class="py-1 text-left bgColor">
          <b class="gray--text mb-0 body-2">Total</b>
        </v-flex>
        <v-flex
          xs3
          sm3
          class="py-1 text-center bgColor">
          <b class="gray--text mb-0 body-2">{{ $n(proposal.allowedOriginalAmount, 'currency') }}</b>
        </v-flex>
        <v-flex
          xs3
          sm3
          class="py-1 text-center bgColor">
          <b class="gray--text mb-0 body-2">{{ $n(proposal.allowedAmount, 'currency') }}</b>
        </v-flex>
      </v-layout>

      <!--
        <v-layout row wrap>
        <v-flex xs12 sm6 class="pb-0">
          <v-checkbox
            class="simple-checkbox"
            v-model="proposal.passExpenses"
            label="Repassar despesas"
            >
          </v-checkbox>
        </v-flex>
        -->
      <template v-if="loading === 0">
        <v-layout
          v-if="proposal.installment.parcel > 1"
          row
          wrap
          class="mb-2">
          <v-flex
            xs4
            sm4 />
          <v-flex
            xs3
            sm3
            class="bgColor">
            <b class="darkGray--text mb-0 subtitle-1">Parcelado em</b>
          </v-flex>
          <v-flex
            xs5
            sm5
            class="bgColor text-center">
            <span class="darkGray--text mb-0 subtitle-1">1x {{ $n(proposal.installments[0].installments[0].value, 'currency') }} + {{ proposal.installment.parcel - 1 }}x {{ $n(proposal.installments[0].installments[1].value, 'currency') }}</span>
          </v-flex>
        </v-layout>

        <v-layout
          row
          wrap>
          <v-flex
            xs6
            sm6 />
          <v-flex
            xs3
            sm3>
            <b class="darkGray--text mb-0 subtitle-1">Total Geral</b>
          </v-flex>
          <v-flex
            xs3
            sm3
            class="bgColor text-center">
            <span class="darkGray--text mb-0 subtitle-1">{{ $n(total, 'currency') }}</span>
          </v-flex>
        </v-layout>
      </template>

      <v-layout
        row
        wrap>
        <v-flex
          xs6
          sm6
          class="pl-0">
          <v-btn
            color="darkGray"
            block
            outlined
            :disabled="loading != 0"
            text
            @click="goToProposalList">
            Cancelar
          </v-btn>
        </v-flex>
        <v-flex
          xs6
          sm6
          class="pr-0">
          <v-btn
            class="mr-0"
            color="primary"
            :disabled="!valid || loading != 0"
            block
            outlined
            @click="createProposal">
            Criar proposta
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>

    <modal
      :hide-actions="loading === true"
      :hide-close="loading === true"
      btn-primary-label="Confirmar e enviar"
      max-width="600"
      :show="modal"
      @close="closeModal"
      @submit="saveModal">
      <template v-if="loading">
        <div slot="content">
          <v-container class="px2 py2">
            <div class="show-loading">
              <loading size="150px" />
            </div>
            <h2 class="title primary--text text-center mb-2">
              Processando
            </h2>
            <p class="textColor--text text-center">
              Aguarde alguns segundos enquanto criamos a proposta...
            </p>
          </v-container>
        </div>
      </template>
      <template v-else>
        <span slot="title">Confirmar nova proposta</span>
        <div slot="content">
          <p>
            Será enviado por chat para <b>{{ this.case.recipient.name }}</b>
            uma nova proposta no valor de <b>{{ $n(total, 'currency') }}</b>
            <span v-if="proposal.installment.parcel > 1"> parcelado em  {{ proposal.installment.parcel }} vezes</span>
            <span v-else> para pagamento à vista</span>
          </p>
        </div>
      </template>
    </modal>
  </v-container>
</template>

<script>
  import Modal from '@/components/ui/Modal'
  import Loading from '@/components/ui/Loading'
  import validate from '@/mixins/validate'
  import masks from '@/mixins/masks'
  import CalculateParam from '@/components/ui/proposals/CalculateParam'
  import TicketsTable from '@/components/ui/TicketsTable'
  import DisplayField from '@/components/ui/DisplayField'
  import { mapGetters } from 'vuex'
  import _ from 'lodash'

  export default {
    components: { Modal, TicketsTable, CalculateParam, Loading, DisplayField },
    mixins: [ validate, masks ],
    data () {
      return {
        showTickets: false,
        loading: 0,
        modal: false,
        valid: true,
        indexes: [
          { id: 'selic' },
          { id: 'igpm' },
          { id: 'inpc' },
          { id: 'ipca' },
        ],
        labelFinancialIndex: null,
        correctionIndex: 'selic',
        checkedGeneralDiscount: false,
        recalculateProposal: {},
        proposal: {
          passExpenses: false,
          days: 5,
          installment: {
            parcel: 1,
            calculateParams: []
          },
          installments: [],
          tickets: [],
          allowedTickets: [],
          discount: {
            type: 'originalValue',
            value: 0
          }
        }
      }
    },
    computed: {
      // eslint-disable-next-line vue/return-in-computed-property
      days () {
        const now = new Date()
        const day = now.getDate()
        const setDate = (day, next = false) => {
          const copy = new Date(now.getTime())
          copy.setDate(day)
          if (next) {
            copy.setMonth(copy.getMonth() + 1)
          }
          const difference = Math.abs(copy.getTime() - now.getTime())
          return {
            text: copy.toLocaleString('pt-BR', { dateStyle: 'short' }),
            value: difference/(1000 * 60 * 60 * 24)
          }
        }
        if (day >= 1 && day < 10) {
          return [
            setDate(10),
            setDate(20),
            setDate(30)
          ]
        } else if (day >= 10 && day < 20) {
          return [
            setDate(20),
            setDate(30),
            setDate(10, true)
          ]
        } else if (day >= 20 && day < 30) {
          return [
            setDate(30),
            setDate(10, true),
            setDate(20, true)
          ]
        } else if (day >= 30) {
          return [
            setDate(10, true),
            setDate(20, true),
            setDate(30, true)
          ]
        }
      },
      ...mapGetters({
        case: 'cases/record'
      }),
      parcelNotDefined () {
        return !this.proposal.installment.parcel
      },
      calculateParams () {
        return this.proposal.installment.calculateParams.reduce((acc, item) => {
          item.basis = this.originalValue
          item.value = item.basis * (item.percentApplied / 100)
          if (item.isCurrency ) {
            item.discountValue = item.discount / 100
          } else {
            item.discountValue = item.discount * item.value / 100
          }
          acc.push(item)
          return acc
        }, [])
      },
      originalValue () {
        this.proposal.tickets.map(ticket => {
          if (ticket.type !== 'expense') {
            const discount = parseFloat((ticket.originalValue * this.proposal.discount.value / 100).toFixed(2))
            ticket.discount = discount
            const basis  = parseFloat((ticket.originalValue - discount).toFixed(2))
            const updates = this.proposal.installment.calculateParams.reduce((acc, item) => acc + (basis * item.percent / 100), 0)
            ticket.currentValue = parseFloat((basis + updates).toFixed(2))
          } else {
            ticket.discount = 0.00
          }
        })
        const originalValue = this.proposal.tickets.reduce((acc, t) => acc + t.originalValue - t.discount, 0)
        return originalValue
      },
      total () {
        let value = this.calculateParams.reduce((acc, item) => {
          return acc + item.value - item.discountValue
        }, parseFloat(this.originalValue))
        return value.toFixed(2)
      },
      parcelValue () {
        return this.total / this.proposal.installment.parcel
      }
    },
    watch: {
      correctionIndex (value) {
        let param = this.getParam('correctionIndex')
        param.name = value
        this.calculate(value)
      },
      ['proposal.installment.parcel'] () {
        this.calculateWidthDebounced()
      },
      ['proposal.days'] () {
        this.calculateWidthDebounced()
      }
    },
    created(){
      this.calculateWidthDebounced = _.debounce(function() {
        this.calculate()
      }, 1000)
    },
    beforeMount () {
      this.loading = true
      this.case.event.then(event => {
        const { calculateParams } = event.data()
        this.calculate(calculateParams.correctionIndex)
      })
    },
    mounted () {
      setTimeout(() => {
        this.focusOnElement()
      }, 300)
    },
    methods: {
      formatDate (date) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit'}
        return new Date(date.replace('-', '/')).toLocaleString('pt-BR', options)
      },
      setDiscountGeneral ({discount}) {
        this.proposal.discount.value = discount * 100
      },
      createProposal () {
        if (this.$refs.form.validate()) {
          this.modal = true
        }
      },
      focusOnElement () {
        this.$refs.focusElem.focus()
      },
      saveModal () {
        this.loading = true
        const currentValue = parseFloat(this.total)
        const calculateParams = this.calculateParams.clone()
        const details = calculateParams.map(detail => {
          detail.value -= detail.discountValue
          // temp values remove to save
          delete detail.percentApplied
          delete detail.discountValue
          delete detail.isCurrency
          return detail
        })
        const proposal = Object.assign(
          {},
          this.proposal,
          {
            details
          }
        )
        proposal.installments = proposal.installments.map((installment) => {
          installment.originalValue = this.originalValue
          installment.currentValue = currentValue
          installment.parcelValue = parseFloat(this.parcelValue)
          installment.parcel = proposal.installment.parcel
          return installment
        })
        delete proposal.installment
        const data = {
          proposal,
          caseId: this.$route.params.caseId,
          parcel: this.proposal.installment.parcel
        }
        return this.$store.dispatch('proposal/create', data)
          .then(this._success)
          .catch(this._failure)
      },
      closeModal () {
        this.modal = false
      },
      getParam (type) {
        return this.proposal.installment.calculateParams.find(param => param.type === type)
      },
      calculateParamChange ({type, discount, isCurrency}) {
        let param = this.getParam(type)
        param.discount = discount
        param.isCurrency = isCurrency
      },
      _success () {
        this.modal = false
        this.loading = 0
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Proposta criada com sucesso'})
        this.goToProposalList()
      },
      _failure () {
        this.loading = 0
        this.$root.$emit('snackbar', {show: true, time: 10000, color: 'error', text: 'houve um problema'})
      },
      calculate (correctionIndex = 'selic') {
        if(!this.proposal.installment.parcel || !correctionIndex){
          return
        }
        const data = {
          caseId: this.$route.params.caseId,
          correctionIndex: correctionIndex,
          parcel: this.proposal.installment.parcel,
          days: this.proposal.days,
          discount: this.proposal.discount
        }
        this.loading = true
        this.$store.dispatch('proposal/calculate', data)
          .then(({data: { proposal } }) => {
            let details = proposal.details.reduce((acc, item) => {
              let percentApplied = item.value / item.basis * 100
              let newItem = Object.assign({}, item, { percentApplied, discount: 0, discountValue: 0, isCurrency: false })
              acc.push(newItem)
              return acc
            }, [])
            let correctionIndex = details.find(params => params.type === 'correctionIndex').name
            this.proposal.installment.calculateParams = details
            this.proposal.tickets = proposal.tickets
            Object.assign(this.proposal, proposal)
            this.getParam('correctionIndex').name = correctionIndex
            this.correctionIndex = correctionIndex
            this.loading = 0
          })
          .catch(this._failure)
      },
      goToProposalList () {
        this.$nextTick(async () => {
          this.$store.dispatch('proposal/firstActiveForCase', this.$route.params.caseId, { root: true })
          this.$emit('cancel')
        })
      }
    }
  }
</script>

<style lang="sass">
  .validation-absolute
    .v-input-group__details
      position: relative
      overflow: initial

    .v-input-group__messages
      position: absolute
      width: 400%
      left: 0
      top: 2px

  .form-proposal
    position: relative
    z-index: 10
    background: #fff

    .v-input-group label
      font-size: 14px

  .wrapper-calculate-params
    min-height: 200px
    position: relative

  .expand-details .v-expansion-panel__header
    padding: 0

</style>
